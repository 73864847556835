import React from "react";
import { Row, Col } from "antd";
import "antd/dist/antd.css";

import "./style.css";
import "../../App.css";
import ReviewCard from "../../Components/ReviewCard";
import ImgCar from "../../Assets/ImgCar.png";
import { CustomNavbar } from "../../Components";

import { CustomFooter } from "../../Components";

import Carousel from "react-elastic-carousel";
import { LandingSignUpScreen } from "../index";
import PricingSectionComplete from "../../Components/PricingSectionComplete";
const LyftVehicleInspectionScreen = ({
  handleReDirect,
  handleLoginRedirect,
  breakPoints,
  handleSubmit,
  handleCheckBox,
  isLoading,
  handleTermsOfUse,
}) => {
  return (
    <div>
      <div
        className="badge-container"
        style={{
          backgroundImage: `url(${"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470909/lyft_banner_rf7gmb_qc7wep.png"})`,
        }}
      >
        <CustomNavbar scrollValue={10} />
        <div className="badge-image-inner-container">
          <div className="badge-inner-left">
            <p className="badge-main-heading">Lyft Vehicle Inspection</p>
            <p className="badge-main-para">
              Chex.<span style={{ color: "#FF7A00" }}>AI</span> offers an online
              Lyft inspection through the app. All you need is a phone with a
              camera
            </p>
            <div className="btn_div_banner">
              <a href="#chexFormCont">
                <button className="btn_styling_banner"> Get Started </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="chexFormCont">
        <LandingSignUpScreen
          handleTermsOfUse={handleTermsOfUse}
          handleLoginRedirect={handleLoginRedirect}
          handleSubmit={handleSubmit}
          handleCheckBox={handleCheckBox}
          isLoading={isLoading}
        />
      </div>

      <section>
        <Row>
          <Col xl={12} md={12} xs={24}>
            <div className="vehicleIncpectionContainer">
              <h3>
                <span style={{ color: " #0d92d7", display: "block" }}>
                  Vehicle Inspection{" "}
                </span>{" "}
                Requirement for Lyft Drivers
              </h3>
              <p>
                Vehicle inspection is a standard requirement for all Turo
                drivers. There are multiple ways to meet the compliance but the
                most popular one is going to a mechanic shop which requires
                appointments and could delay getting certification by a few
                days.
              </p>
              <p>
                Chex.AI is a modern and advanced solution to meet Turo’s car
                inspection requirement without having to go to any mechanic.
                Just simply visit our app via any web browser, upload photos and
                video clips from your driveway or any convenient location. Its
                that simple!
              </p>

              <ul className="vehicleListContainer">
                <li>No appointments to the mechanic shops</li>
                <li>Complete inspection at your convenience</li>
                <li>Get certified same day</li>
              </ul>
            </div>
          </Col>
          <Col xl={12} md={12} xs={24}>
            <div className="vehicleIncpectionContainerImg">
              <img src={ImgCar} alt="" />
            </div>
          </Col>
        </Row>
      </section>

      <div className="chexWorks-main-container-lyft">
        <div className="chexwork-inner-container">
          {/*<p className='chaxwork-heading'>How Chex<span className='chaxwork-heading-span'>.AI</span> works</p>*/}
          <Row>
            <Col xs={24} sm={24} md={12} lg={6}>
              <div className="chexworks-column">
                <img
                  src="https://res.cloudinary.com/dgjordf6e/image/upload/v1665470819/box1_asayud_y2klfg.png"
                  alt=""
                  className="chexwork-img"
                />
                <h3 className="chexwork-image-title">Register</h3>
                <p className="chexwork-image-desc">
                  {" "}
                  Please{" "}
                  <a href="#chexFormCont">
                    <span
                      style={{ cursor: "pointer" }}
                      className="chexwork-image-getStarted-span"
                    >
                      Register
                    </span>
                  </a>{" "}
                  your account by filling out the form above to get started
                </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <div className="chexworks-column">
                <img
                  src="https://res.cloudinary.com/dgjordf6e/image/upload/v1666094066/box2_bw2ymd_xl5r0c.jpg"
                  alt=""
                  className="chexwork-img"
                />
                <h3 className="chexwork-image-title">Complete Inspection</h3>
                <p className="chexwork-image-desc">
                  Follow the instructions for each inspection point within our
                  app and upload photo and video clips
                </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <div className="chexworks-column">
                <img
                  src="https://res.cloudinary.com/dgjordf6e/image/upload/v1665470819/box4_wnrb9i_pqfy0m.png"
                  alt=""
                  className="chexwork-img"
                />
                <h3 className="chexwork-image-title">Complete Payment</h3>
                <p className="chexwork-image-desc">
                  Enter payment details.
                  <span className="chexwork-image-payment-span">
                    {" "}
                    If you don't pass, your first re-inspection is free
                  </span>
                </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <div className="chexworks-column">
                <img
                  src="https://res.cloudinary.com/dgjordf6e/image/upload/v1665470819/box3_qthium_qqmori.png"
                  alt=""
                  className="chexwork-img"
                />
                <h3 className="chexwork-image-title">Same-day Certification</h3>
                <p className="chexwork-image-desc">
                  All certifications completed within{" "}
                  <span className="chexwork-image-getStarted-span">
                    {" "}
                    4 hours{" "}
                  </span>{" "}
                  or less. Downloadable verification in-app
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <PricingSectionComplete
        bgColor={"#F4F9FD"}
        pricingPoint1={"Inspection results within 4 hours"}
        pricingPoint2={"Verified certification in-app"}
        priceColor={"#2584E0"}
        pricingPoint3={"24/7 customer support"}
        pricingPoint4={"No appointments needed"}
      />

      <section className="main_section_review">
        <Row>
          <Col xl={24} md={24} xs={24} style={{ backgroundColor: "white" }}>
            <h2 className="headingCustomerReviews">
              What our <span style={{ color: "#2584E0" }}> Customers </span> are
              saying?
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xl={24} md={24} xs={24}>
            <div className="review_container_main">
              <Carousel
                breakPoints={breakPoints}
                pagination={false}
                showArrows={false}
              >
                <div className="items">
                  <ReviewCard
                    reviewText="Chex.ai was really easy to use, better than going to the mechanic!"
                    reviewerName={"Andressa Amorim"}
                    ratingStar={5}
                  />
                </div>
                <div className="items">
                  <ReviewCard
                    reviewText=" The app was easy to follow, the pictures showing what was required of me to take made is simple. Upload was fast. They responded quickly and had the inspection back within a half hour! Thank you. Well worth the money."
                    reviewerName={"Mousa Naseer"}
                    ratingStar={5}
                  />
                </div>
                <div className="items">
                  <ReviewCard
                    reviewText="Quick and efficient! Great price and easy to upload all photos and videos required. Will definitely recommend and use it next year!"
                    reviewerName={"Mubarak Behi"}
                    ratingStar={5}
                  />
                </div>
                <div className="items">
                  <ReviewCard
                    reviewText="This is an awesome service. They have made it so easy that a child could do it. I’ve been doing Rideshare for five years now I have tried other services and this is by far the best! Easiest to complete and lowest price that I’ve seen out there."
                    reviewerName={"Ali"}
                    ratingStar={5}
                  />
                </div>
                <div className="items">
                  <ReviewCard
                    reviewText="Easy, app based Turo vehicle inspection. I had an issue and had to call support. The phone was picked up immediately by a person. Excellent service. I would definitely use Chex.AI again!"
                    reviewerName={"Sal Villa"}
                    ratingStar={5}
                  />
                </div>
              </Carousel>
            </div>
          </Col>
        </Row>
      </section>

      <CustomFooter />
    </div>
  );
};

export default LyftVehicleInspectionScreen;
