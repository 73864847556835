import React from "react";
import "./style.css";
import { Col, Row } from "antd";
import { GoPlus } from "react-icons/go";
import PricingCard from "../PricingCard";

const PricingSectionComplete = (props) => {
  return (
    <section
      className="pricingSections_uber"
      style={{ backgroundColor: props.bgColor }}
      id="pricingSectionComplete"
    >
      <Row>
        <Col>
          <h3 className="headingPricing">Pricing</h3>
          <p className="paragraphPricing">
            Convenience isn’t the only benefit of using Chex.AI. We offer best
            pricing for Turo & Lyft Inspections along with best in class
            customer support.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xl={6} md={12} xs={12}>
          <div className="pricingPoints">
            <GoPlus size={18} style={{ color: "#FF7A00", marginRight: 10 }} />
            <p className="pricingPointsText">{props.pricingPoint1}</p>
          </div>
        </Col>
        <Col xl={6} md={12} xs={12}>
          <div className="pricingPoints">
            <GoPlus size={18} style={{ color: "#FF7A00", marginRight: 10 }} />
            <p className="pricingPointsText">{props.pricingPoint2}</p>
          </div>
        </Col>
        <Col xl={6} md={12} xs={12}>
          <div className="pricingPoints">
            <GoPlus size={18} style={{ color: "#FF7A00", marginRight: 10 }} />
            <p className="pricingPointsText">
              <span style={{ color: "#FF7A00" }}>
                {" "}
                {props.pricePointNumber ? props.pricePointNumber : null}{" "}
              </span>{" "}
              {props.pricingPoint3}
            </p>
          </div>
        </Col>
        <Col xl={6} md={12} xs={12}>
          <div className="pricingPoints">
            <GoPlus size={18} style={{ color: "#FF7A00", marginRight: 10 }} />
            <p className="pricingPointsText">{props.pricingPoint4}</p>
          </div>
        </Col>
      </Row>

      <Row className="pricingCardRow">
        <Col xl={8} md={8} xs={24} className="pricingColumn">
          <div className="priceCardCompo">
            <PricingCard
              tagText="LYFT INSPECTION"
              priceText="$ 29.99"
              DiscountPriceText="$ 29.99"
              priceColor={props.priceColor}
              ParagraphText="Single Inspection"
              colorCode="#0d92d7"
            />
          </div>
        </Col>
        <Col xl={8} md={8} xs={24} className="pricingColumn">
          <div className="priceCardCompo">
            <PricingCard
              tagText="TURO INSPECTION"
              priceText="$ 29.99"
              DiscountPriceText="$ 29.99"
              priceColor={props.priceColor}
              ParagraphText="Single Inspection"
            />
          </div>
        </Col>
        <Col xl={8} md={8} xs={24} className="pricingColumn">
          <div className="priceCardCompo">
            <PricingCard
              tagText="BUNDLED INSPECTION"
              priceText="$ 47.98"
              DiscountPriceText="$ 47.98"
              priceColor={props.priceColor}
              ParagraphText="Turo & Lyft Combined"
              colorCode="#FF5A5A"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={24} md={24} xs={24}>
          <div className="btn_div">
            <a href="#chexFormCont">
              <button className="btn_styling_uber new_btn_pricingSection">
                {" "}
                Get Started{" "}
              </button>
            </a>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default PricingSectionComplete;
