import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { Button } from "../../Components";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import EmailNotificationModal from "../../Components/EmailNotificationModal/EmailNotificationModal";
import "../../Components/Input/style";
import "./style.css";

const ContactUsScreen = ({
  handleSubmit,
  handleChange,
  loading,
  handleClick,
  emailError,
  setEmailError,
  formData,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(true);

  useEffect(() => {
    setIsModalVisible(true);
  }, []);

  const handleModal = (visible) => {
    setIsModalVisible(visible);
  };

  const validateEmail = (email) => {
    if (!email) {
      return "Email is required";
    }
    if (!email.includes("@")) {
      return "Email must contain @";
    }
    if (!email.includes(".")) {
      return "Email must contain a domain (e.g., .com)";
    }
    const [localPart, domain] = email.split("@");
    if (localPart.length === 0) {
      return "Email must have a username before @";
    }
    if (domain.length === 0) {
      return "Email must have a domain after @";
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return "Invalid email format";
    }
    return "";
  };

  const handleEmailChange = (e) => {
    handleChange(e);
    const validationError = validateEmail(e.target.value);
    setEmailError(validationError);
  };

  const isSubmitDisabled = () => {
    return !formData.email || emailError;
  };

  return (
    <div className="contact-us-main_container">
      {/* <EmailNotificationModal
        isVisible={isModalVisible}
        handleModal={handleModal}
      /> */}
      <div className="contact-us-container">
        <div className="contact-us-screen_header">
          <Link to="/selectOption">
            <IoIosClose size={40} color="white" onClick={handleClick} />
          </Link>
        </div>
        <div className="contact-us-form_container">
          <p className="contact-us-text">Contact Us</p>
          <div className="input-fields-width">
            <input
              style={{ fontFamily: "Poppins" }}
              name="name"
              className="input-field"
              placeholder="Enter Full Name"
              onChange={handleChange}
            />
            <input
              style={{ fontFamily: "Poppins", marginTop: "10px" }}
              name="email"
              type="email"
              value={formData.email}
              className={`input-field ${emailError ? "error" : ""}`}
              placeholder="Enter Email Address"
              onChange={handleEmailChange}
              required
            />
            {emailError && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {emailError}
              </span>
            )}
          </div>
          <div className="text-area-field">
            <textarea
              className="text-area-styling"
              rows="10"
              name="message"
              cols="50"
              placeholder="How can we help you?"
              onChange={handleChange}
            />
          </div>
          <Button
            loading={loading}
            title={"Submit"}
            onClickButton={handleSubmit}
            // disabled={isSubmitDisabled()}
            buttonClass="contact-us-submit-btn"
          />
          <div className="or-reach-text">
            <b>or</b> <div className="or-line"></div>
          </div>
          <p className="directlt-reach-text">Email directly to:</p>
          <div className="email-container">
            <a className="email-text" href="mailto:support@chex.ai">
              support@chex.ai
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsScreen;
