import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useToasts } from "react-toast-notifications";
import ActionCreators from "../../actions";
import { ContactUsScreen } from "../../Screens";
import { setCompanies } from "../../utils/functions";
import { useHistory } from "react-router-dom";

const ContactUsContainer = (props) => {
  const history = useHistory();

  const [data, setData] = useState({
    message: "",
    name: "",
    email: "",
  });
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const handleChange = (event) => {
    const obj = { ...data };
    obj[event.target.name] = event.target.value;
    setData(obj);
  };

  const handleClick = () => {
    history.goBack();
  };

  const handleSubmit = () => {
    if (emailError || !data.email) {
      addToast("Please enter a valid email address", { appearance: "error" });
      return;
    }

    const { contactUs } = props;
    const params = {
      ...data,
    };
    contactUs(params, addToast, setLoading, {}, handleClick);
  };

  return (
    <ContactUsScreen
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      loading={loading}
      handleClick={handleClick}
      emailError={emailError}
      setEmailError={setEmailError}
      formData={data}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    userData: state.userData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsContainer);
