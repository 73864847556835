import React, { useEffect } from "react";
import { CustomNavbar } from "../index";
import "./style.css";
import { Link } from "react-router-dom";
import { Col } from "antd";

const LandingBannerComponent = ({
  redirectionPage,
  HomeLandingText,
  RideshareText,
  BannerImage,
  BannerTextPara,
  image_banner_class,
  firstCardRef,
  handleScroll,
}) => {
  const Route = window.location.pathname;

  // Define background images for different routes
  const backgroundImage = {
    "/": "url('https://res.cloudinary.com/dgjordf6e/image/upload/v1726233443/main-section-background_q4gnqh.png')",
    "/ride-share":
      "url('https://res.cloudinary.com/dgjordf6e/image/upload/v1726744537/midsection-man-standing-car_1_ixwdx2.png')",
    "/uber-inspection":
      "url('https://res.cloudinary.com/dgjordf6e/image/upload/v1726744537/midsection-man-standing-car_1_ixwdx2.png')",
    "/fleet-inspection":
      "url('https://res.cloudinary.com/dgjordf6e/image/upload/v1726233443/main-section-background_q4gnqh.png')",
  };

  // This is the home page
  const HomeHeaderText = "vehicle safety inspection & damage detection";
  const HomeHeaderSpanText = "aI powered";
  const HomeHeaderParagraph =
    "CHEX.AI's Car Damage Recognition is an advanced AI solution that instantly detects dents, scratches, and body defects in vehicles using smartphone photos.";
  const HomeHeaderRoute = "/getStarted";
  const HomeHeaderImage =
    "https://res.cloudinary.com/dgjordf6e/image/upload/v1726236730/chex-landing-page-header-image_dxct5w.png";

  // This is the rideshare page
  const RideShareHeaderText = "INSPECTION PLATFORM";
  const RideShareHeaderSpanText = "Vehicle";
  const RideShareHeaderParagraph =
    "Our solution is designed for convenience. Complete inspection requirement online through your phone";
  const RideShareHeaderRoute = "/getStarted";
  const RideShareHeaderImage =
    "https://res.cloudinary.com/dgjordf6e/image/upload/v1726744536/Group_3465213_kukchy.png";

  // This is the Fleet&DSP page
  const DSPHeaderText = "Vehicle Damage Detection And Inspection Platform.";
  const DSPHeaderSpanText = "Fleet & DSP";
  const DSPHeaderParagraph = "";
  const DSPHeaderRoute = "/contactPage";
  const DSPHeaderImage =
    "https://res.cloudinary.com/dgjordf6e/image/upload/v1726744738/Group_3465212_a94330.png";

  return (
    <div className="landing_banner_main_wrapper">
      <div
        className="landing_main_section_container"
        style={{ backgroundImage: backgroundImage[Route] }}
      >
        <CustomNavbar scrollValue={30} />
        <div className="landing_main_section_content">
          <div className="landing_main_section_inner">
            <div className="landing_main_section_text">
              <h1 className="landing_main_section-heading">
                <span className="highlight">
                  {Route === "/"
                    ? HomeHeaderSpanText
                    : Route === "/ride-share" || Route === "/uber-inspection"
                    ? RideShareHeaderSpanText
                    : DSPHeaderSpanText}
                </span>{" "}
                {Route === "/"
                  ? HomeHeaderText
                  : Route === "/ride-share" || Route === "/uber-inspection"
                  ? RideShareHeaderText
                  : DSPHeaderText}
              </h1>
              <p className="landing_main_section_description">
                {Route === "/"
                  ? HomeHeaderParagraph
                  : Route === "/ride-share" || Route === "/uber-inspection"
                  ? RideShareHeaderParagraph
                  : DSPHeaderParagraph}
              </p>
              <div className="btn_landing_wrapper">
                {firstCardRef ? (
                  <button className="btn_landing" onClick={handleScroll}>
                    Get Started
                  </button>
                ) : (
                  <Link
                    to={`${
                      redirectionPage
                        ? redirectionPage
                        : `${
                            Route === "/"
                              ? HomeHeaderRoute
                              : Route === "/ride-share" ||
                                Route === "/uber-inspection"
                              ? RideShareHeaderRoute
                              : DSPHeaderRoute
                          }`
                    }`}
                  >
                    <a className="btn_landing" href="">
                      Get Started
                    </a>
                  </Link>
                )}
              </div>
            </div>
            <div className="landing_main_section_image">
              <img
                src={
                  Route === "/"
                    ? HomeHeaderImage
                    : Route === "/ride-share" || Route === "/uber-inspection"
                    ? RideShareHeaderImage
                    : DSPHeaderImage
                }
                alt="Placeholder"
                className="landing_image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingBannerComponent;
